import React from 'react';
import './App.scss';
import { WaveComponent } from './Components/Wave.component/Wave.component';
import { HeaderComponent } from './Components/Header.component/Header.component';
import { CopyrightComponent } from './Components/Copyright.component/Copyright.component';
import { AboutUsComponent } from './Components/AboutUs.component/AboutUs.component';

function App() {
  return (
    <div className='App'>
      <HeaderComponent />
      <WaveComponent topColor='#000125' bottomColor='#fff' />
      <AboutUsComponent />
      <WaveComponent topColor='#fff' bottomColor='#7d2986' />
      <CopyrightComponent />
    </div>
  );
}

export default App;
