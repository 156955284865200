import React from 'react';
import Logo from '../../Assets/Logo.png';

import './Header.component.scss';
import { Fade } from 'react-awesome-reveal';

export const HeaderComponent = () => {
    return (
        <div id="header">
            <Fade cascade triggerOnce>
                <img src={ Logo } alt="Chimera Code LLC" />
            </Fade>
            <h1>ChimeraCode</h1>
            <h4>Where innovation meets precision</h4>
        </div>
    );
}