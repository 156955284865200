import React from 'react';

interface ImageTileComponentProps {
    imgUrl: string,
    alt: string
}

export const ImageTileComponent : React.FC<ImageTileComponentProps> = ({imgUrl, alt}) => {
    return (
        <div style={{width: '100%', textAlign: 'center'}}>
            <img src={imgUrl} alt={alt} style={{
                width: '75%',
                height: '75%',
                borderRadius: '25%'
            }} />
        </div>
    );
}