import React from 'react';

import './Copyright.component.scss';

export const CopyrightComponent = () => (
    <div id="copyright">
        <p>
            <br />
            <br />
            &copy;
            {new Date().getFullYear()}
            &nbsp;Copyright ChimeraCode LLC - All Rights Reserved
        </p>
    </div>
)