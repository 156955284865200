import React from 'react';
import { DataType } from 'csstype'

interface WaveComponentProps {
    topColor: DataType.Color,
    bottomColor: DataType.Color
}

export const WaveComponent : React.FC<WaveComponentProps> = ({topColor, bottomColor})  => {
    return (
        <div style={{
            position: 'relative',
            padding: 0,
            margin: '-70px 0 0 0',
            height: '70px',
            zIndex: 1000,
        }}>
            <div style={{
                display: 'block',
                position: 'absolute',
                borderRadius: '100% 50%',

                width: '54%',
                height: '109%',
                backgroundColor: bottomColor,
                right: '0%',
                top: '60%',
            }}></div>
            <div style={{
                position: 'absolute',
                height: '70px',
                width: '100%',
                bottom: 0
            }}>

            </div>
            <div style={{
                display: 'block',
                position: 'absolute',
                borderRadius: '100% 50%',
                
                width: '54%',
                height: '100%',
                backgroundColor: topColor,
                left: '-1.5%',
                top: '40%',
            }}></div>
        </div>
    );
}