import React from 'react';
import AI from '../../Assets/AI.jpg';
import Apps from '../../Assets/Apps.jpg';
import Network from '../../Assets/Network.jpg';

import './AboutUs.component.scss';
import { Fade } from 'react-awesome-reveal';
import { ImageTileComponent } from '../ImageTile.component/ImageTile.component';

export const AboutUsComponent = () => (
    <div id="about-us">
        <Fade cascade triggerOnce>
        <div className='card'>
            <ImageTileComponent imgUrl={Network} alt="Cutting edge technology" />
            {/* <img src={Network} alt="Cutting edge technology" /> */}
            <p>Welcome to <strong>ChimeraCode</strong> - We blend cutting-edge technology with creative solutions to <strong>transform</strong> your digital dreams into reality.</p>
        </div>
        <div className='card'>
            <ImageTileComponent imgUrl={Apps} alt="Excelence and Innovation" />
            <p><strong>Pioneers in Technology</strong>. Our journey began with a passion for excellence in software engineering. We are a team of creative thinkers and problem-solvers dedicated to delivering <strong>groundbreaking</strong> software solutions.</p>
        </div>
        <div className='card'>
            <ImageTileComponent imgUrl={AI} alt="Machine Learning"/>
            <p>Crafting Your Digital Future. From bespoke software development to AI and machine learning solutions, ChimeraCode offers a comprehensive range of services to help your business <strong>thrive in the digital age</strong>.</p>
        </div>
        </Fade>
    </div>
);